import cn from 'classnames'
import { ReactNode, useEffect, useState } from 'react'
import { darkTheme, lightTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit'
import produce from 'immer'
import { useIsDarkMode } from '../common/hooks'
import { Provider as PopupProvider } from '../popup/Provider'
import * as wallets from '@rainbow-me/rainbowkit/wallets'
import {
    Chain,
    connectorsForWallets,
    Wallet,
} from '@rainbow-me/rainbowkit'
import {
    createClient,
    WagmiConfig,
} from 'wagmi'
import { chains, provider } from '../web3/chains'

const walletIndexes = [
    'metaMask',
    'walletConnect',
    'rainbow',
    'coinbase',
    'ledger',
    'trust',
    'argent'
]

function getWalletIndex(walletName: string) {
    for (let i = 0; i < walletIndexes.length; i++) {
        if (walletName.includes(walletIndexes[i])) return i
    }

    return walletIndexes.length
}

const connectors = connectorsForWallets([
    {
        groupName: 'Recommended',
        wallets: Object.keys(wallets)
            .sort((a, b) => getWalletIndex(a) - getWalletIndex(b))
            .map(name => ((wallets as Record<string, (arg: { chains: Chain[], appName: string }) => Wallet>)[name])({ chains, appName: 'repaya.io' })),
    },
])

const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider
})

function RainbowWrapper({ children }: { children?: ReactNode }) {
    const [show, setShow] = useState(false)
    const isDarkMode = useIsDarkMode()

    useEffect(() => {
        setShow(true)
    }, [])

    if (!show) return <>{children}</>

    return (
        <RainbowKitProvider
            id="rk_provider"
            theme={produce(recipe => {
                recipe.shadows.connectButton = 'none'
            })(isDarkMode ? darkTheme() : lightTheme())}
            // modalSize='compact'
            chains={chains}
            appInfo={{
                appName: 'repaya.io'
            }}>
            {children}
        </RainbowKitProvider>
    )
}

interface Props {
    children?: ReactNode
}

export default function AppProviders({
    children
}: Props) {
    return (
        <WagmiConfig client={wagmiClient}>
            <RainbowWrapper>
                <PopupProvider>
                    {children}
                </PopupProvider>
            </RainbowWrapper>
        </WagmiConfig>
    )
}