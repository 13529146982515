import { ConnectButton } from '@rainbow-me/rainbowkit'
import cn from 'classnames'
import { useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import ClientSide from 'ui/ClientSide'
import { useAddress } from 'web3/hooks'
import { useEvent } from '../common/hooks'
import { Button } from '../ui/Button'
import Account from './Account'
import { useLogout, useSignin, useUser } from './hooks'

interface Props {
    hideConnectWallet?: boolean
}

export default function Auth({
    hideConnectWallet
}: Props) {
    const [signin] = useSignin()
    const address = useAddress()
    const isSigninImmediatelyRef = useRef(false)
    const [logout] = useLogout()
    const [_, isLoading] = useUser()
    const handleLogout = useEvent(() => logout())

    const handleConnect = useEvent((cb: Function) => {
        isSigninImmediatelyRef.current = true
        cb()
    })

    useEffect(() => {
        if (!address || !isSigninImmediatelyRef.current) return
        isSigninImmediatelyRef.current = false

        signin()
    }, [address])

    return (
        <ClientSide>
            <ConnectButton.Custom>{({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                mounted,
            }) => {
                const connected = mounted && !!account && !!chain
                if (!connected) {
                    if (hideConnectWallet) return null
                    return <Button onClick={() => handleConnect(openConnectModal)}>
                        <FormattedMessage defaultMessage="Connect wallet" id="VASo1/" />
                    </Button>
                }

                if (chain.unsupported) {
                    return (
                        <>
                            <Button className="hidden sm:inline-block" onClick={() => openChainModal()}>
                                <FormattedMessage defaultMessage="Switch chain" id="ptNDgs" />
                            </Button>
                            <Button className="inline-block sm:hidden" onClick={() => openChainModal()}>
                                <FormattedMessage defaultMessage="Switch" id="n1Whvj" />
                            </Button>
                            <Button isLoading={isLoading} onClick={handleLogout}>
                                <FormattedMessage defaultMessage="Logout" id="C81/uG" />
                            </Button>
                        </>
                    )
                }

                return (
                    <Account isMobileView />
                )
            }}</ConnectButton.Custom>
        </ClientSide>
    )
}