import create from "zustand"

export const usePopupStore = create<{
    elem: Element | null,
    open: number[]
    index: number
}>(() => ({
    elem: null,
    open: [],
    index: 0
}))

export function useIsAnyPopupOpen() {
    return usePopupStore(state => state.open.length > 0 && state.elem != null)
}
