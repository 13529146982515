import { Coin } from "coin/types";
import { BigNumber, utils } from "ethers";
import { useMemo } from "react";
import { erc20ABI, useContractRead, useBalance as useEthBalance, useAccount } from "wagmi";
import { chains } from "./chains";

export function useBalance(coin: Coin | null, address?: string | null) {
    const tokenCfg: Parameters<typeof useContractRead>[0] = {
        abi: erc20ABI,
        functionName: 'balanceOf',
        args: [address as `0x${string}`],
        chainId: chains[0].id
    }
    if (address && coin?.type === 'token') {
        tokenCfg.address = coin.data.address
    }

    const { data: tokenBalance, isError: isTokenError, isLoading: isTokenLoading } = useContractRead(tokenCfg)

    const ethCfg: Parameters<typeof useEthBalance>[0] = {
        chainId: chains[0].id
    }

    if (address && coin?.type === 'native' && coin.code === 'ETH') {
        ethCfg.address = address as `0x${string}`
    }

    const { data: ethBalance, isError: isEthError, isLoading: isEthLoading } = useEthBalance(ethCfg)

    return useMemo(() => {
        if (coin?.type === 'token') {
            return {
                data: tokenBalance ? utils.formatUnits(tokenBalance as BigNumber, coin.data.decimals) : null,
                isError: isTokenError,
                isLoading: isTokenLoading
            }
        } else if (coin?.type === 'native' && coin.code === 'ETH') {
            return {
                data: ethBalance?.formatted ?? null,
                isError: isEthError,
                isLoading: isEthLoading
            }
        }
        return { data: null, isError: false, isLoading: false }
    }, [coin, tokenBalance, isTokenError, isTokenLoading, ethBalance, isEthError, isEthLoading])
}

export function useAddress() {
    const account = useAccount()
    return account?.address
}