export interface Storage {
    length: number
    clear(): void
    getItem(key: string): string | null
    key(index: number): string | null
    removeItem(key: string): void
    setItem(key: string, value: string): void
}

export class IsoLocalStorage implements Storage {
    get length(): number {
        if (typeof window === 'undefined') return 0
        return localStorage.length
    }

    clear(): void {
        if (typeof window === 'undefined') return
        localStorage.clear()
    }

    getItem(key: string): string | null {
        if (typeof window === 'undefined') return null

        return localStorage.getItem(key)
    }

    key(index: number): string | null {
        if (typeof window === 'undefined') return null

        return localStorage.key(index)
    }

    removeItem(key: string): void {
        if (typeof window === 'undefined') return

        return localStorage.removeItem(key)
    }

    setItem(key: string, value: string): void {
        if (typeof window === 'undefined') return

        return localStorage.setItem(key, value)
    }
}

export const storage = new IsoLocalStorage()