import cn from 'classnames'
import Auth from '../auth/Auth'
import UiHeader from '../ui/Header'
import Logo from '../ui/logo-dark.svg'
import { useUser } from '../auth/hooks'
import { ReactNode } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { chainName, chains } from 'web3/chains'
import { Option, Select } from 'ui/Select'
import { FormattedMessage } from 'react-intl'

interface MenuLinkProps {
    to: string
    route: string
    className?: string
    isExternal?: boolean
    isLast?: boolean
    children?: ReactNode
}

function MenuLink({ to, route, className, children, isLast, isExternal }: MenuLinkProps) {
    const isActive = route === to
    let externalProps = {}
    if (isExternal) externalProps = { rel: "noopener noreferrer", target: "_blank" }

    return (
        <Link href={to} {...externalProps}>
            <div className={cn("no-underline transition-all duration-200 dark:text-neutral-50 hover:opacity-100 opacity-60 text-neutral-900", {
                'dark:text-neutral-50 text-neutral-900 opacity-100': isActive,
                'mr-4 sm:mr-6 md:mr-6': !isLast
            }, className)}>{children}</div>
        </Link>
    )
}

interface Props {
    hideNetworkSelect?: boolean
    hideMenu?: boolean
    hideConnectButton?: boolean
}

const chain = chains[0]

export default function Header({
    hideNetworkSelect,
    hideMenu,
    hideConnectButton
}: Props) {
    const [user] = useUser()
    const { route } = useRouter()

    return (
        <UiHeader>
            <div className='flex flex-row items-center justify-between p-4 pt-5 px-4 xl:px-12'>
                <div className="flex flex-row md:flex-row items-center justify-start gap-2 md:gap-2 mr-3">
                    <Link href={user ? '/account' : '/'} className='group sm:px-8 pl-0  relative hidden sm:block'>
                        <Logo className='w-20 transition-all duration-200 dark:group-hover:saturate-100 saturate-0 relative' style={{ top: 2 }} />
                    </Link>
                    {!hideNetworkSelect && (
                        <Select
                            size="small"
                            placement='bottom-start'
                            className='m-0'
                            options={[
                                {
                                    value: 1, label: { name: 'Mainnet', link: "https://repaya.io" }
                                },
                                {
                                    value: 5, label: { name: 'Goerli', link: "https://goerli.repaya.io" }
                                },
                            ]}
                            renderOption={(option, isSelected) => (
                                <a className='block py-2 px-4 hover:dark:bg-neutral-800 rounded-2xl' href={option.label.link}>{option.label.name}</a>
                            )}
                            renderValue={(option, isOpen) => (
                                <span className={cn({
                                    'dark:text-neutral-500': !isOpen
                                })}>{option.label.name}</span>
                            )}
                            value={chain.id}
                        />
                    )}
                </div>
                <div className='grow' />
                <div className='sm:ml-4 max-sm:text-sm flex flex-row items-center'>
                    {!hideMenu && (
                        <div className="flex flex-row items-center justify-end mr-0 md:mr-0">
                            <MenuLink route={route} className="" to="https://docs.repaya.io/" isExternal>
                                <FormattedMessage defaultMessage="Docs" id="/6/sPX" />
                            </MenuLink>
                            {user && (
                                <MenuLink route={route} className="" to="/account">
                                    <FormattedMessage defaultMessage="Account" id="TwyMau" />
                                </MenuLink>
                            )}
                            {chain.id === 1 && (
                                <MenuLink route={route} className={cn({
                                    'hidden lg:block': !!user
                                })} to="https://docs.repaya.io/pricing" isExternal>
                                    <FormattedMessage defaultMessage="Pricing" id="5Wwopw" />
                                </MenuLink>
                            )}
                            <MenuLink route={route} className="" to="https://repaya.freshdesk.com/support/home" isExternal>
                                <FormattedMessage defaultMessage="Support" id="HqRNN8" />
                            </MenuLink>
                        </div>
                    )}
                    <Auth hideConnectWallet={hideConnectButton} />
                </div>
            </div>
        </UiHeader>
    )
}

//   https://repaya.freshdesk.com/support/home