import produce from 'immer'
import create from 'zustand'
import { withPersistentStore } from '../common/state'
import { storage } from '../common/storage'
import { IntegrationForm, PaymentForm, PaymentFormType, PaymentRequestForm } from './types'
import { clientSideHook, useAsync, useEvent } from "../common/hooks"
import { useSignMessage } from 'wagmi'
import { wait } from '@repaya/commons/async2'
import { useRefreshUser, useSignin } from '../auth/hooks'
import { useEffect, useRef } from 'react'
import { request } from '../api/request'
import { useRouter } from 'next/router'
import { useAddress } from 'web3/hooks'
import { useSWRConfig } from 'swr'

export interface DraftState {
    form: Partial<PaymentForm> | null
    type: PaymentFormType | null
    linkId: string | null
    lastEditedAt: number
}

export interface PaymentFormDraftState {
    drafts: Record<string, DraftState>
    get(key: string): {
        form: Partial<PaymentForm> | null
        type: PaymentFormType | null
        linkId: string | null
        lastEditedAt: number
    }
    delete(key: string): void
    setType(key: string, type: PaymentFormType | null): void
    update(key: string, form: Partial<PaymentForm>): void
    load(key: string, type: PaymentFormType, form: PaymentForm, linkId: string): void
}

const useBasePaymentFormDraftStore = create<PaymentFormDraftState>((set, get) => ({
    drafts: {},
    get: key => {
        const now = Date.now()
        const state: DraftState = (get().drafts[key] ?? { type: null, form: null, linkId: null, lastEditedAt: now })
        return state

        return { type: null, form: null, linkId: null, lastEditedAt: now }
    },
    delete: (key) => set(produce((recipe: Partial<PaymentFormDraftState>) => {
        if (!(key in recipe.drafts!)) return
        recipe.drafts![key].type = null
        recipe.drafts![key].form = null
        recipe.drafts![key].linkId = null
    })),
    setType: (key, type) => set(produce((recipe: Partial<PaymentFormDraftState>) => {
        if (!(key in recipe.drafts!)) {
            recipe.drafts![key] = { form: null, type: null, linkId: null, lastEditedAt: Date.now() }
        }
        if (recipe.drafts![key]?.type !== type) recipe.drafts![key].form = null
        recipe.drafts![key].type = type
    })),
    update: (key, update) => set(prev => {
        const existing = prev.drafts[key] ?? { form: null, type: null, linkId: null }
        const form = Object.assign({}, existing.form ?? {}, update)

        return { ...prev, drafts: { ...prev.drafts, [key]: { ...existing, lastEditedAt: Date.now(), form } } }
    }),
    load: (key, type, form, linkId) => set(produce(recipe => {
        if (!(key in recipe.drafts!)) {
            recipe.drafts![key] = { form: null, type: null, linkId: null }
        }
        recipe.drafts![key].type = type
        recipe.drafts![key].form = form
        recipe.drafts![key].linkId = linkId
        recipe.drafts![key].lastEditedAt = Date.now()
    }))
}))

export const usePersistedPaymentFormDraftStore = withPersistentStore(
    storage,
    'payment-form-draft.v5',
    useBasePaymentFormDraftStore
)

export const usePaymentFormDraftStore = usePersistedPaymentFormDraftStore


interface PaymentFormState {
    type: PaymentFormType | null
    form: PaymentForm | null
    creator: string | null
    linkId: string | null
    signature: string | null
    delete(): void
    set(type: PaymentFormType, form: PaymentForm, linkId: string, creator: string, signature: string): void
}

function serializeFormForSigning(form: PaymentForm, type: PaymentFormType): string {
    return JSON.stringify({
        payments_provider: 'https://repaya.io',
        [type === 'request' ? 'invoice' : 'payments_integration']: form,
        nonce: Date.now() * Math.random()
    })
}

export function useSigninAndSavePaymentForm(key: string) {
    const [signin] = useSignin()
    const router = useRouter()
    const refreshUser = useRefreshUser()
    const { mutate } = useSWRConfig()

    return useAsync(async function* () {
        const state = usePaymentFormDraftStore.getState().drafts[key]
        const [form, type, editingLinkId] = [state.form! as PaymentForm, state.type!, state.linkId]

        const msg = serializeFormForSigning(form!, type)
        const result = yield* wait(signin(msg))
        if (!result) return null
        const { user, signature } = result
        if (!signature) return null

        const params: Record<string, any> = {
            form: msg,
            sign: signature,
            address: user.address,
        }
        if (editingLinkId) params.linkId = editingLinkId
        const linkId = yield* request<string>('savePaymentForm', params)

        if (editingLinkId) mutate(['getPaymentForm', editingLinkId])
        refreshUser()
        router.push(`/account/form/${linkId}`)
        usePaymentFormDraftStore.getState().delete(key)
        return linkId
    })
}

export function useSavePaymentForm(key: string): [() => Promise<string | null>, boolean] {
    const address = useAddress()
    const { signMessageAsync } = useSignMessage()
    const router = useRouter()
    const refreshUser = useRefreshUser()
    const { mutate } = useSWRConfig()

    return useAsync(async function* () {
        const state = usePaymentFormDraftStore.getState().drafts[key]
        const [form, type, editingLinkId] = [state.form! as PaymentForm, state.type!, state.linkId]

        if (!address) return null
        const msg = JSON.stringify({
            [type === 'request' ? 'invoice' : 'payments_integration']: form,
            provider: process.env.NEXT_PUBLIC_URL!,
            nonce: Date.now() * Math.random()
        })
        const signature: string = yield* wait(signMessageAsync({ message: msg }))

        const params: Record<string, any> = {
            form: msg,
            sign: signature,
            address: address.toLowerCase(),
        }
        if (editingLinkId) params.linkId = editingLinkId
        const linkId: string = yield* request<string>('savePaymentForm', params)

        refreshUser()

        if (editingLinkId) mutate(['getPaymentForm', editingLinkId])
        router.push(`/account/form/${linkId}`)
        usePaymentFormDraftStore.getState().delete(key)
        return linkId
    })
}

export function useSelectFormType(key: string): (type: PaymentFormType) => void {
    return useEvent(type => {
        console.log('set', type)
        usePaymentFormDraftStore.getState().setType(key, type)
    })
}

export function getIsInfoFilledIn(type: PaymentFormType | null, draft: Partial<PaymentForm> | null): boolean {
    if (draft == null) return false
    if (type === 'request') return 'title' in draft && !!draft.title
    if (type === 'integration') return 'storeDomain' in draft && !!draft.storeDomain
    return false
}

export function getIsAmountsFilledIn(draft: Partial<PaymentForm> | null): boolean {
    if (!draft) return false

    if ('amounts' in draft) return Object.keys((draft as PaymentRequestForm).amounts).length > 0
    if ('coins' in draft) return (draft as IntegrationForm).coins.length > 0
    return false
}

export function useEditPaymentForm(key: string) {
    return useEvent((linkId: string, type: PaymentFormType, form: PaymentForm) => {
        usePaymentFormDraftStore.getState().load(key, type!, form, linkId)
    })
}

export function useCancelEditPaymentForm(key: string) {
    return useEvent(() => {
        const draft = usePaymentFormDraftStore.getState().drafts[key]
        if (draft.linkId == null) return

        usePaymentFormDraftStore.getState().delete(key)
    })
}