import { CancelablePromise, wait } from "@repaya/commons/async2"
import { request } from "api/request"
import { format } from "date-fns"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { useSignMessage } from "wagmi"
import { useAddress } from "web3/hooks"
import { useAsync } from "../common/hooks"
import { isRejectedSigningError, isUnauthorizedError } from "./error"
import { useAuthStore } from "./state"
import { User } from "./types"

export function useUser(): [User | null, boolean] {
    const address = useAddress()
    const [logout] = useLogout()
    const [user, isLoading] = useAuthStore(state => [state.user, state.isLoading])

    useEffect(() => {
        if (!user || user.address.toLowerCase() === address?.toLowerCase()) return
        if (useAuthStore.getState().isLoading) return

        logout()
    }, [address, user])

    return [user, isLoading]
}

export function useSignin(): [(msg?: string) => CancelablePromise<{ user: User, signature?: string } | null>, boolean] {
    const address = useAddress()
    const { signMessageAsync } = useSignMessage()

    return useAsync(async function* (msg?: string) {
        if (!address) return null
        let user = useAuthStore.getState().user
        if (user?.address === address.toLowerCase()) return { user }

        useAuthStore.getState().setLoading()

        const msgArg = typeof msg === 'string' && msg ? msg : null

        const messageToSign = `${msgArg ?? `Signing in to repaya.io\n\nThis message never gets published on the blockchain. Only you can see it.\nDate: ${format(new Date(), 'yyyy-MM-dd\'T\'HH:mm:ssXX')}`}`

        let signature: string
        try {
            signature = yield* wait(signMessageAsync({ message: messageToSign }))
        } catch (error) {
            if (isRejectedSigningError(error)) {
                useAuthStore.getState().setUser(null)
                return null
            }
            useAuthStore.getState().setLoading(false)
            throw error
        }

        user = yield* request<User>('authenticate', {
            msg: messageToSign,
            sign: signature,
            address: address.toLowerCase()
        })

        useAuthStore.getState().setUser(user)
        return {
            user,
            signature
        }
    })
}

export function useLogout() {
    const { pathname } = useRouter()

    return useAsync(async function* () {
        let user = useAuthStore.getState().user
        if (!user) return

        useAuthStore.getState().setLoading()
        try {
            yield* request('logout', [])
        } catch (error) {
            useAuthStore.getState().setUser(null)
            if (typeof window !== 'undefined' && !pathname.startsWith('/checkout')) {
                window.location.href = '/'
            }
            if (isUnauthorizedError(error)) return
            throw error
        }
    })
}

export function useRefreshUser() {
    return useAuthStore(state => state.refresh)
}