import { memo, ReactNode, useEffect, useRef } from "react"
import cn from 'classnames'
import { useIsAnyPopupOpen, usePopupStore } from "./state"

export const Provider = memo(function Provider({ children, className }: { children?: ReactNode, className?: string }) {
    const ref = useRef<HTMLDivElement | null>(null)
    const popupState = usePopupStore()

    useEffect(() => {
        if (ref.current !== popupState.elem) {
            usePopupStore.setState(prev => ({ ...prev, elem: ref.current }))
        }

        return () => { }
    }, [popupState])

    useEffect(() => () => usePopupStore.setState(prev => ({ ...prev, elem: null})), [])

    const isAnyPopupOpen = useIsAnyPopupOpen()

    return (
        <>
            <div className={cn("popup-filter-blur", { 
                'popup-filter-blur-open': isAnyPopupOpen
            }, className)}>
                {children}
            </div>
            <div className={cn("transition-colors opacity-40 pointer-events-none duration-200 fixed z-10 inset-0", {
                'bg-neutral-200 dark:bg-neutral-900': isAnyPopupOpen
            })} />
            <div className="popups" ref={ref}></div>
        </>
    )
})