import cn from 'classnames'
import { ReactNode } from 'react'

interface Props {
    isBorderless?: boolean
    isTransparent?: boolean
    children?: ReactNode
}

export default function Header({
    isBorderless,
    isTransparent,
    children
}: Props) {
    return (
        <div className={cn('sticky flex flex-row items-stretch w-full header-blur top-0 z-20 dark:bg-opacity-90 bg-opacity-0 bg-neutral-50  w-screena left-0 right-0 ', {
            'border border-x-0 border-t-0 border-neutral-100 dark:border-neutral-800 dark:border-opacity-50': !isBorderless,
            'dark:bg-neutral-900': !isTransparent,
            '': isTransparent
        })} style={{
            height: 74
        }}>
            {/* <div className="absolute inset-0 z-10 bg-neutral-50 dark:bg-neutral-900" style={{top: -300, bottom: -20}}></div> */}
            <div className="relative z-20 w-full">
                {children}
            </div>
        </div>
        
    )
}