import cn from 'classnames'
import { useAsync, useEvent } from '../common/hooks'
import { Button } from '../ui/Button'
import { useLogout, useSignin, useUser } from './hooks'
import Avatar from 'boring-avatars'
import { usePaymentFormDraftStore } from '../payment-form-setup/state'
import Address from '../ui/Address'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useAddress } from 'web3/hooks'
import { FormattedMessage } from 'react-intl'

interface Props {
    isMobileView: boolean
}

export default function Account({
    isMobileView
}: Props) {
    const address = useAddress()
    const [signin] = useSignin()
    const [logout] = useLogout()
    const [user, isLoading] = useUser()

    const router = useRouter()

    const [handleSignin] = useAsync(async function* () {
        await signin()
        const form = usePaymentFormDraftStore.getState().get('').form
        if (form == null) return router.push('/account')
        if ('storeDomain' in form && !form.storeDomain) return router.push('/account')
        if ('title' in form && !form.title) return router.push('/account')
    })

    const handleLogout = useEvent(() => logout())

    if (!address) return null

    return (
        <div className='flex flex-row items-center justify-start prose prose-neutral dark:prose-invert max-w-none'>
            {!user && (
                <>
                    <span>&nbsp;</span> <Button isLoading={isLoading} onClick={handleSignin}>
                        <FormattedMessage defaultMessage="Sign in" id="SQJto2" />
                    </Button>
                </>
            )}
            {user && (
                <>
                    <Link href="/account" className={cn('flex flex-row items-center no-underline cursor-pointer group mr-4 md:mr-8', {
                        'hidden md:flex': isMobileView
                    })}>
                        <div className='mr-3 relative' style={{ top: -2 }}>
                            <Avatar
                                size={22}
                                name={address}
                                variant="marble"
                                colors={["#92A1C6", "#146A7C", "#F0AB3D", "#C271B4", "#C20D90"]}
                            />
                        </div>
                        <span className='font-normal relative transition-colors duration-200 text-neutral-500 group-hover:text-black dark:text-neutral-300 dark:group-hover:text-white' style={{ lineHeight: 1, letterSpacing: '1px' }}>
                            <Address isResponsive address={address} />
                        </span>
                    </Link>
                    <Button isDisabled={isLoading} onClick={handleLogout}>
                        <FormattedMessage defaultMessage="Logout" id="C81/uG" />
                    </Button>
                </>
            )}
        </div>
    )
}